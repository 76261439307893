<template>
  
  <Portlet title="📚 Knowledge Base"
           :loading="isLoading">
        <div class="pa-2"
             v-if="!isLoading">
            <v-btn :elevation="0"
                    color="primary"
                    class="mr-2 mb-2"
                    v-if="!isEmpty"
                    :href="current.url"
                    target="_blank"
                    :block="$vuetify.breakpoint.smAndDown">
                <v-icon class="mr-2">mdi-download</v-icon>
                Download
            </v-btn>
            <v-btn :elevation="0"
                   @click="generate"
                   class="mb-2"
                   :block="$vuetify.breakpoint.smAndDown">
                <v-icon class="mr-2">mdi-refresh</v-icon>
                Generate
            </v-btn>
        </div>
        <Message v-if="hasPending">
            Pending exports:
            <ul>
                <li v-for="data in pending"
                    :key="data.id">
                    <strong>#{{ data.id }}</strong> del 
                    {{ moment(data.created_at).format('DD/MM/YYYY') }} 
                    <span v-if="data.started_at">(started)</span>
                </li>
            </ul>
        </Message>
        <!-- list -->
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div>
                <v-icon class="mr-2">mdi-history</v-icon> 
                Export precedenti
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-0">
              <div v-if="hasExports">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Date
                        </th>
                        <th class="text-left">
                          Status
                        </th>
                        <th class="text-center">
                          Type
                        </th>
                        <th class="text-right">
                          <!-- actions -->
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in items"
                        :key="item.id">
                        <td>{{ moment(item.created_at).format('DD/MM/YYYY HH:mm') }}</td>
                        <td>
                          <v-chip small
                                  :color="statusColor(item)">
                            {{ status(item) }}
                          </v-chip>
                        </td>
                        <td class="text-center">
                          <v-chip small>
                            {{ item.type }}
                          </v-chip>
                        </td>
                        <td>
                          <v-btn icon
                            color="primary"
                            size="small"
                            v-if="item.url"
                            :href="item.url"
                            target="_blank">
                            <v-icon>mdi-download</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <v-pagination
                  v-model="page"
                  :length="pages"
                  @input="onPageChange"
                  @next="onPageNext"
                  @previous="onPagePrevious"
                  class="py-3"
                ></v-pagination>
              </div>
              <v-alert v-else
                       type="info"
                       text
                       class="ma-3">
                No export data yet.
              </v-alert>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
    </Portlet>
  
  </template>
  
  <script>
  
    import _ from 'lodash'
    import moment from 'moment'
  
    import Api from '../../api'
    import Message from '../Message'
    import Portlet from './Portlet'
  
    export default {
      name: 'WidgetExport',
  
      components: {
        Message,
        Portlet
      },
  
      props: {},
  
      data: () => ({
        isLoading: true,
        items: [],
        page: 1,
        pages: 0
      }),
  
      computed: {
        current()
        {
          return this.items.filter((item)=>{
            return item.started_at && item.type == 'gpt'
          }).shift()
        },
        hasExports()
        {
          return this.items.length > 0
        },
        hasPending()
        {
          return !this.isLoading && !_.isEmpty(this.pending)
        },
        isEmpty()
        {
          return !this.isLoading && _.isEmpty(this.current)
        },
        pending()
        {
          return this.items.filter((item)=>{
            return !item.completed_at
          })
        }
      },
  
      mounted()
      {
        this.load()
      },
  
      methods: 
      {
        moment,

        generate()
        {
            this.isLoading = true

            Api.exports.generate('gpt')
                .then(()=>{
                    this.load()
                })
                .finally(()=>{ this.isLoading = false })
        },

        load()
        {
            this.isLoading = true

            Api.exports.list({ page:this.page })
                .then(({ data })=>{
                    this.items = data.data
                    this.page = data.meta.current_page
                    this.pages = data.meta.last_page
                })
                .finally(()=>{ this.isLoading = false })
        },

        status(item)
        {
          if( !item.started_at )
            return 'pending'
          
          if( !item.completed_at )
            return 'progress'

          return 'completed'
        },

        statusColor(item)
        {
          const status = this.status(item)
          
          switch(status)
          {
            case 'pending': return '';
            case 'progress': return 'info';
            case 'completed': return 'success';
          }
        }
      },

      watch:
      {
        page()
        {
          this.load()
        }
      }
  
    }
  </script>
  