import './style/main.scss'

import App from './App.vue'
import PortalVue from 'portal-vue'
import store from './store'
import Vue from 'vue'
import VueMq from 'vue-mq'
import VueQuagga from 'vue-quaggajs'
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify'

const axios = require('axios')

Vue.config.productionTip = false

Vue.use(PortalVue)
Vue.use(VueQuagga)
Vue.use(VueRouter)
Vue.use(require('vue-shortkey'))

Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    sm: 768,
    md: 1250,
    lg: Infinity,
  },
  defaultBreakpoint: 'sm' // customize this for SSR
})

/**
 * Custom components.
 */

import BookCard from './components/book/Card'

Vue.component('BookCard', BookCard)

/**
 * Custom filters.
 */

Vue.filter('ucfirst', (value)=>{
  if(!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('uppercase', (value)=>{
  if(!value) return ''
  value = value.toString()
  return value.toUpperCase()
})

/**
 * Router.
 */

import router from './router'

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
  created () {
    /**
     * Get local user data.
     */
    const userInfo = localStorage.getItem('user')
    if (userInfo) {
      const userData = JSON.parse(userInfo)
      this.$store.commit('setUserData', userData)
    }
    /**
     * Get user favourites.
     */
    let isLogged = this.$store.getters['isLogged']
    const userFavourites = localStorage.getItem('favourites')
    if (userFavourites) {
      const favourites = JSON.parse(userFavourites)
      this.$store.commit('setUserFavourites', favourites)
    }
    else if( isLogged ) {
      this.$store.dispatch('loadFavourites')
    }
    /**
     * If APIs get 401 logout user.
     */
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          this.$store.dispatch('logout')
        }
        return Promise.reject(error)
      }
    )
  }
}).$mount('#app')
