<template>
  
  <v-card class="mx-auto" :loading="loading">
    
    <v-list-item v-if="title" class="grey lighten-4">
      <v-list-item-content>
        <v-list-item-title class="headline">
          {{ title }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ subtitle }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <slot></slot>

  </v-card>

</template>

<script>

  export default {
    name: 'Portlet',

    components: {},

    props: {
      loading: {
        type: Boolean,
        default: false
      },
      subtitle: {
        type: String
      },
      title: {
        type: String
      }
    },

    data: () => ({
    }),

    mounted(){},

    methods: {}

  }
</script>
