const apiKey = process.env.VUE_APP_GOOGLE_CLOUD_KEY

const axios = require('axios')

import _ from 'lodash'

var instance = axios.create()
    delete instance.defaults.withCredentials

export default {
  
  getUrl( endpoint, domain="vision" )
  {
    return 'https://' + domain + '.googleapis.com/v1/' 
              + endpoint 
              + '?key=' + apiKey
  },

  /**
   * Recognize text from image.
   */
  ocr( imageBase64 )
  {
    let url = this.getUrl('images:annotate')

    if( !_.isArray(imageBase64) )
      imageBase64 = [imageBase64]

    var requests = _.map(imageBase64, (image)=>{
      return {
        image: {
          content: image
        },
        features: [{ type: 'TEXT_DETECTION' }]
      }
    })   
    
    return instance.post(url, {
      requests
    })
  }

}