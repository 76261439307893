<template>
  <v-chip
    class="ma-2"
    :color="toColour(item.model)"
    dark
    close
    @click:close="onClose"
  >
    <v-icon left>
      {{ item.icon }}
    </v-icon>
    {{ item.text }}
  </v-chip>
</template>

<script>
  import utility from '@/mixins/utility'

  export default {
    name: 'Chip',

    components: {},

    mixins: [utility],

    props: {
      item: {
        type: Object,
        default()
        {
          return {}
        }
      }
    },

    data: () => ({
      
    }),

    mounted(){},

    methods: {
      onClick()
      {
        //this.$emit('click', this.book)
      },
      onClose()
      {
        this.$emit('close', this.item)
      }
    }

  }
</script>
