<template>
  
  <v-container fluid>

    <Portlet title="⭐️ Chart" :loading="isLoading">

      <Message v-if="isEmpty">
        No books rated yet.
      </Message>

      <v-list-item two-line v-else v-for="book in books" 
                            :key="book.id"
                            @click="open(book)">
        <v-list-item-content>
          <v-list-item-title>
            {{ book.title }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-rating
              color="warning"
              size="15"
              readonly
              :value="book.rating"
            ></v-rating>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <!-- Chart page button -->
      <v-divider/>
      <v-list-item @click="goToChart" class="text-center grey lighten-5">
        <v-list-item-title class="primary--text">
          View Chart
          <v-icon class="ml-1">mdi-chevron-right</v-icon>
        </v-list-item-title>
      </v-list-item>
    </Portlet>

  </v-container>

</template>

<script>

  import _ from 'lodash'

  import Api from '../../api'
  import Message from '../Message'
  import Portlet from './Portlet'

  export default {
    name: 'WidgetChart',

    components: {
      Message,
      Portlet
    },

    props: {},

    data: () => ({
      books: [],
      isLoading: true
    }),

    computed: {
      isEmpty()
      {
        return !this.isLoading && _.isEmpty(this.books)
      }
    },

    mounted()
    {
      this.loadBooks()
    },

    methods: {
      goToChart()
      {
        this.$router.push({ name: 'chart' })
      },
      loadBooks()
      {
        Api.books.chart()
           .then((res)=>{
            this.books = res.data.data
            this.isLoading = false
           })
      },
      open(book)
      {
        this.$router.push({ name: 'book', params: { bookId: book.id } })
      }
    }

  }
</script>
