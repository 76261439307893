<template>

  <v-dialog
      v-model="dialog"
      :fullscreen="$mq=='sm'">

    <v-card>
      <v-card-title
        class="headline red darken-2 white--text"
        primary-title
      >
        <span v-if="isUpdate">Edit Website</span>
        <span v-else>Add an Website</span>
      </v-card-title>

      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Title"
                  v-model="theWebsite.title"
                  v-on:keyup.enter="save"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="URL"
                  v-model="theWebsite.url"
                  v-on:keyup.enter="save"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="grey">
        <v-btn text dark @click="dialog = false">
          <v-icon class="mr-1">mdi-close</v-icon>
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" 
               @click="save">
          <v-icon class="mr-1">mdi-check</v-icon>
          Save
        </v-btn>
      </v-card-actions>

    </v-card>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

  </v-dialog>

</template>

<script>

  import Api from '../../api'

  export default {
    name: 'WebsiteModal',

    components: {},

    props: {
      websiteId: {
        type: Number
      },
      website: {
        type: Object,
        default: ()=> { return {} }
      }
    },

    data: () => ({
      dialog: false,
      overlay: false,
      theWebsite: {},
    }),

    computed: {
      isUpdate()
      {
        return this.theWebsite.id
      }
    },

    mounted(){
      if(this.website)
        this.theWebsite = this.website
    },

    methods: {
      save()
      {
        this.overlay = true

        var website = this.theWebsite
          
        var ws = website.id ? 
                 Api.websites.update(website.id, website) : 
                 Api.websites.create(website)
        
        ws.then((res)=>{
            
            this.overlay = false
            this.dialog = false
            this.$emit('save', res.data.data)

          })
          .catch((e)=>{
            this.overlay = false
            this.$store.commit('growl', {
              color: 'error',
              text: e.response.data.message
            })
          })

      },
      reset()
      {
        //this.theArticle = {}
      },
      show()
      {
        this.dialog = true
      }
    },

    watch: {
      dialog(isOpening)
      {
        if( !isOpening )
          this.reset()
      },
      website(website)
      {
        if(website)
          this.theWebsite = website
      },
      websiteId(websiteId)
      {
        if(websiteId)
          Api.websites.one(websiteId)
                       .then((res)=>{
                        this.theWebsite = res.data.data
                       })
      }
    },

  }
</script>
