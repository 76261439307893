<template>
  
  <v-container fluid>

    <Portlet title="📚 Last read" :loading="isLoading">
      <v-list-item two-line v-for="book in books" 
                            :key="book.id"
                            @click="open(book)">
        <v-list-item-content>
          <v-list-item-title>
            {{ book.title }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ authors(book) }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <Message v-if="!books.length">You didn't read a book yet.</Message>
    </Portlet>

  </v-container>

</template>

<script>

  import _ from 'lodash'
  import Api from '../../api'
  import Message from '../Message'
  import Portlet from './Portlet'

  export default {
    name: 'WidgetReadBooks',

    components: {
      Message,
      Portlet
    },

    props: {},

    data: () => ({
      books: [],
      isLoading: true
    }),

    mounted()
    {
      this.loadBooks()
    },

    methods: {
      authors(book)
      {
        return _.chain(book.authors)
                .map(a => a.fullname)
                .value()
                .join(', ')
      },
      loadBooks()
      {
        Api.books.read()
           .then((res)=>{
            this.books = _.chain(res.data.data)
                          .toArray()
                          .orderBy('read_at','desc')
                          .value()
            this.isLoading = false
           })
      },
      open(book)
      {
        this.$router.push({ name: 'book', params: { bookId: book.id } })
      }
    }

  }
</script>
