<template>
  
  <v-container fluid>

    <Portlet title="📰 New magazines" :loading="isLoading">
      <v-list-item two-line v-for="issue in issues" 
                            :key="issue.id"
                            @click="open(issue)">
        <v-list-item-content>
          <v-list-item-title>
            {{ issue.title }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="issue.magazine">
            {{ issue.magazine.title }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </Portlet>

  </v-container>

</template>

<script>

  import _ from 'lodash'
  import Api from '../../api'
  import Portlet from './Portlet'

  export default {
    name: 'WidgetLastIssues',

    components: {
      Portlet
    },

    props: {},

    data: () => ({
      issues: [],
      isLoading: true
    }),

    mounted()
    {
      this.loadIssues()
    },

    methods: {
      loadIssues()
      {
        Api.issues.list({ sort:'desc', orderBy:'created_at' })
           .then((res)=>{
            
            let issues = res.data.data,
                magazineIds = _.map(issues, 'magazine_id')

            this.issues = issues
            
            Api.magazines.list({ in:magazineIds })
               .then((res)=>{
                
                let magazines = res.data.data

                _.map(this.issues, (issue)=>{
                  issue.magazine = _.find(magazines, { id:issue.magazine_id })
                })

                this.isLoading = false

               })

           })
      },
      open(issue)
      {
        this.$router.push({ name: 'issue', params: { issueId: issue.id } })
      }
    }

  }
</script>
