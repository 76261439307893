<template>
  
  <v-container fluid>

    <Portlet class="portlet-stats" title="📊 Statistics" :loading="isLoading">
      <v-list-item two-line v-for="(count,stat) in stats" 
                            :key="stat"
                            @click="goTo(stat)">
        <v-list-item-icon>
          <span class="red--text red lighten-5">
            {{ count | inK }}
          </span>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ stat | ucfirst }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </Portlet>

  </v-container>

</template>

<style type="text/css">
  .portlet-stats .v-list-item:nth-child(odd) {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }
  .portlet-stats .v-list-item .v-list-item__icon {
    margin: 15px 30px 15px 0;
  }
  .portlet-stats .v-list-item .v-list-item__icon span {
    border-radius: 100%;
    background-color: #eee;
    width: 56px;
    line-height: 56px;
    font-size: 1.3em;
    text-align: center;
  }
  .portlet-stats .v-list-item .v-list-item__content {
    padding: 0;
  }
  .portlet-stats .v-list-item .v-list-item__title {
    font-size: 1.4em;
    line-height: 56px;
  }
</style>

<script>
  import Api from '../../api'
  import Portlet from './Portlet'

  export default {
    name: 'WidgetStats',

    components: {
      Portlet
    },

    props: {},

    data: () => ({
      stats: {},
      isLoading: true
    }),

    filters: {
      inK(value)
      {
        if(value >= 1000)
          return Number((value/1000).toFixed(1)) + 'K'

        return value
      }
    },

    mounted()
    {
      this.loadStats()
    },

    methods: {
      goTo(route)
      {
        /**
         * Some items doesn't have a page yet.
         */
        const blacklist = [
          'articles',
          'issues', 
          'quotes'
        ]
        
        if( blacklist.indexOf(route) >= 0 )
          return

        this.$router.push(route)
      },
      loadStats()
      {
        Api.stats()
           .then((res)=>{
            this.stats = res.data
            this.isLoading = false
           })
      }
    }

  }
</script>
