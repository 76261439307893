<template>
  
  <v-container fluid>

    <Portlet title="📕 New books" :loading="isLoading">
      <v-list-item two-line v-for="book in books" 
                            :key="book.id"
                            @click="open(book)">
        <v-list-item-content>
          <v-list-item-title>
            {{ book.title }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ authors(book) }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </Portlet>

  </v-container>

</template>

<script>

  import _ from 'lodash'
  import Api from '../../api'
  import Portlet from './Portlet'

  export default {
    name: 'WidgetLastBooks',

    components: {
      Portlet
    },

    props: {},

    data: () => ({
      books: [],
      isLoading: true
    }),

    mounted()
    {
      this.loadBooks()
    },

    methods: {
      authors(book)
      {
        return _.chain(book.authors)
                .map(a => a.fullname)
                .value()
                .join(', ')
      },
      loadBooks()
      {
        Api.books.list({ sort:'desc', orderBy:'created_at' })
           .then((res)=>{
            this.books = res.data.data
            this.isLoading = false
           })
      },
      open(book)
      {
        this.$router.push({ name: 'book', params: { bookId: book.id } })
      }
    }

  }
</script>
